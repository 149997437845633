import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../layout';
import AssetsContext from '../assetsContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import BodyContainer from '../BodyContainer';
import BodyComponents from '../BodyComponents';
import HeaderWithOnlyLogo from '../HeaderWithOnlyLogo';
import Helmet from 'react-helmet';

function generateBodySection({ component, params }, bodySectionId, mobileView) {
  if (BodyComponents[component]) {
    const SectionComponent = BodyComponents[component];
    // console.log(SectionComponent);
    return <SectionComponent {...params} mobileView={mobileView} key={component + bodySectionId}/>;
  } else {
    return <div>{component}</div>;
  }
}

const generateBodySections = (bodySections, mobileView) => {
  return Object.getOwnPropertyNames(bodySections).map((bodySectionId) => {
    // console.log(bodySections[bodySectionId]);
    return generateBodySection(bodySections[bodySectionId], bodySectionId, mobileView);
  });
};

const PlatformPage = (props) => {
  // console.log('initializing issues page');
  const [t] = useTranslation(['platform','translation']);
  const { width } = useWindowDimensions();

  let mobileView = false;

  const files = useContext(AssetsContext).fileUrls;

  const bodySections = {
    ...t('bodySections', { returnObjects: true }),
    // ...sharedSettings.bodySections // no shared settings for this page
  };

  if (width < 750 && width > 380) {
    mobileView = 'mid';
  } else if (width <= 380) {
    mobileView = 'small';
  } else {
    mobileView = false;
  }

  return (
    <Layout>
      <Helmet>
        <title>{t('translation:siteTitle')} - {t('pageTitle')}</title>
      </Helmet>
      <HeaderWithOnlyLogo
        mobileView={mobileView}
        logoImageURL={files[t('logoImageName')]}
      />
      <BodyContainer>
        {generateBodySections(bodySections, mobileView)}
      </BodyContainer>
    </Layout>
  );
};

export default PlatformPage;
