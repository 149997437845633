import React from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/useWindowDimensions';

// const HeaderImageContainer = styled.div`
//   width: 100%;
//   height: ${(props) => props.dimensions.height}px;
// `;

// const HeaderColorOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: rgba(154, 147, 147, 0.23);
//   z-index: 99;
//   height: ${(props) => props.dimensions.height}px;
// `;

// const HeaderImage = styled.img`
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
// `;

const LogoImageContainer = styled.div`
  width: 100%;
  height: 100%;
  width: 100%;
  height: auto;
  padding-top: 75px;
  vertical-align: center;
`;

const LogoImage = styled.img`
  display: block;
  bottom: 40px;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 990px;
`;

const LogoHeaderContainer = styled.div`
  width: 100%;
  position: relative;
`;

const Header = ({ headerImageURL, logoImageURL }) => {
  const { width } = useWindowDimensions();

  function getContainerDimensions(windowWidth) {
    let height;

    if (windowWidth > 952) {
      height = 609.08;
    } else if (windowWidth > 783) {
      height = 0.75 * windowWidth;
    } else {
      height = 0.9 * windowWidth;
    }

    return {
      height,
      width: windowWidth,
    };
  }

  function getLogoWidth(windowWidth) {
    let width;

    if (windowWidth > 952) {
      width = 600;
    } else if (windowWidth > 783 || windowWidth > 550) {
      width = 275;
    } else {
      width = 225;
    }

    return width;
  }

  return (
    <>
      <LogoHeaderContainer>
        <LogoImageContainer dimensions={getContainerDimensions(width)}>
          <LogoImage width={getLogoWidth(width)} src={logoImageURL} />
        </LogoImageContainer>
      </LogoHeaderContainer>
    </>
  );
};

export default Header;
